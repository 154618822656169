import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ExchangeSchema } from '../../shared/schemas';

@Injectable()
export class ExchangeApi {
  private endpoint = 'exchanges/';
  private statuses = {
    ACCEPTED_BY_USER: '1',
    REJECTED_BY_USER: '2',
    ACCEPTED_BY_SUPERVISOR: '3',
    REJECTED_BY_SUPERVISOR: '4',
    REMOVE_REQUESTED_BY_SUPERVISOR: '5',
    REMOVED_CHANGE_OF_SCHEDULE: '6',
  };

  public constructor(private gateway: ApiGateway) {}

  public fetch(id, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + id, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Exchange', res)),
      map((data) => normalize(data, ExchangeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public add(data, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Exchange', res)),
      map((data) => normalize(data, ExchangeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(id, data, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + id, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Exchange', res)),
      map((data) => normalize(data, ExchangeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public loadAllPending(dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + '?status[0]=Pending employee&status[1]=Pending supervisor';

    return this.gateway.get(url, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Exchange', res)),
      map((data) => normalize(data, arrayOf(ExchangeSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public loadIncoming(id, dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + '?incoming_user=' + id + '&status[0]=Pending employee&status[1]=Pending supervisor';

    return this.gateway.get(url, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Exchange', res)),
      map((data) => normalize(data, arrayOf(ExchangeSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public loadOutgoing(id, dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + '?requested_by=' + id + '&status[0]=Pending employee&status[1]=Pending supervisor';

    return this.gateway.get(url, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Exchange', res)),
      map((data) => normalize(data, arrayOf(ExchangeSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public acceptIncoming(data, dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + 'accept/' + this.statuses.ACCEPTED_BY_USER;
    return this.handleExchangeRequest(url, data, dispatchStart);
  }

  public rejectIncoming(data, dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + 'accept/' + this.statuses.REJECTED_BY_USER;
    return this.handleExchangeRequest(url, data, dispatchStart);
  }

  public acceptBySupervisor(data, dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + 'accept/' + this.statuses.ACCEPTED_BY_SUPERVISOR;
    return this.handleExchangeRequest(url, data, dispatchStart);
  }

  public rejectedBySupervisor(data, dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + 'accept/' + this.statuses.REJECTED_BY_SUPERVISOR;
    return this.handleExchangeRequest(url, data, dispatchStart);
  }

  public handleExchangeRequest(url, data, dispatchStart): Observable<any> {
    return this.gateway.put(url, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Exchange', res)),
      map((data) => normalize(data, ExchangeSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public deleteExchangeRequest(id, dispatchStart?: Action): Observable<any> {
    const url = this.endpoint + '/' + id;
    return this.gateway.delete(url, undefined, dispatchStart);
  }

  public getQualifiedUsersForRoster(scheduleId: string) {
    return this.gateway.get(this.endpoint + 'shift_qualified/' + scheduleId);
  }
}
