import { Injectable } from '@angular/core';
import { compose, select, Store } from '@ngrx/store';
import find from 'lodash-es/find';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { getArrayDifference } from '../../../shared/array.helper';
import { periodFilter } from '../../../shared/date.helper';
import { FeatureService } from '../../../startup/feature.service';
import { getAuthenticatedUserId } from '../../auth/auth.service';
import { getPermissionState, hasPermission, PermissionCheck } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { ScheduleModel } from '../schedule/schedule.model';
import { getEnhancedScheduleEntities } from '../schedule/schedule.service';
import { ExchangeAction } from './exchange.action';
import { ExchangeApi } from './exchange.api';
import { getEmployeeIdsPerTeamForDepartment } from './exchange.helper';
import { ExchangeModel, ExchangeWithScheduleModel } from './exchange.model';

@Injectable()
export class ExchangeService {
  public constructor(
    private store: Store<AppState>,
    private api: ExchangeApi,
    private featureService: FeatureService,
  ) {}

  public fetch(exchangeId) {
    return this.api.fetch(exchangeId, ExchangeAction.fetch(exchangeId)).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.fetchSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(ExchangeAction.fetchFailed(exchangeId, response));
        return observableThrowError(response);
      }),
    );
  }

  public add(data): Observable<any> {
    return this.api.add(data, ExchangeAction.add(data)).pipe(
      map((response) => {
        this.store.dispatch(ExchangeAction.addSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ExchangeAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, data) {
    return this.api.update(id, data, ExchangeAction.update(id, data)).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.updateSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.updateFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public loadAllPending(): Observable<any> {
    return this.api.loadAllPending(ExchangeAction.loadAllPending()).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.loadAllPendingSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.loadAllPendingFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public loadIncoming(userId: string): Observable<any> {
    return this.api.loadIncoming(userId, ExchangeAction.loadIncoming()).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.loadIncomingSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.loadIncomingFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public loadOutgoing(userId: string): Observable<any> {
    return this.api.loadOutgoing(userId, ExchangeAction.loadOutgoing()).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.loadOutgoingSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.loadOutgoingFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public acceptIncoming(data): Observable<any> {
    return this.api.acceptIncoming(data, ExchangeAction.acceptIncoming()).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.acceptIncomingSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.acceptIncomingFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public rejectIncoming(data): Observable<any> {
    return this.api.rejectIncoming(data, ExchangeAction.rejectIncoming()).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.rejectIncomingSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.rejectIncomingFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public acceptedBySupervisor(data): Observable<any> {
    return this.api.acceptBySupervisor(data, ExchangeAction.acceptedBySupervisor()).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.acceptedBySupervisorSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.acceptedBySupervisorFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public rejectedBySupervisor(data): Observable<any> {
    return this.api.rejectedBySupervisor(data, ExchangeAction.rejectedBySupervisor()).pipe(
      tap((response) => {
        this.store.dispatch(ExchangeAction.rejectedBySupervisorSuccess(response));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.rejectedBySupervisorFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public deleteExchangeRequest(exchange) {
    return this.api.deleteExchangeRequest(exchange.id, ExchangeAction.deleteExchangeRequest()).pipe(
      tap(() => {
        this.store.dispatch(ExchangeAction.deleteExchangeRequestSuccess(exchange));
      }),
      catchError((error) => {
        this.store.dispatch(ExchangeAction.deleteExchangeRequestFailed(error));
        return observableThrowError(error);
      }),
    );
  }

  public getUnqualifiedEmployeeIdsForExchange(schedule: ScheduleModel) {
    let availableEmployeeIds = [];
    if (!schedule || !schedule.Shift) {
      return [];
    }
    return this.store.pipe(
      select(getEmployeeIdsPerTeamForDepartment([], schedule.date, schedule.date, schedule.department_id)),
      switchMap((employeeIds) => {
        availableEmployeeIds = employeeIds;
        if (!schedule.Shift.Skill || schedule.Shift.Skill.length === 0) {
          return of(availableEmployeeIds);
        }
        return this.api.getQualifiedUsersForRoster(schedule.id);
      }),
      map((employeeIds) => getArrayDifference(availableEmployeeIds, employeeIds)),
    );
  }
}

export const getExchangeState = (state: AppState) => state.orm.exchanges;
export const getExchangeIds = compose((state) => state.items, getExchangeState);

export const getExchangeEntities = createSelector(getExchangeState, (state) => state.itemsById);

export const sortExchanges = (exchanges: ExchangeModel[]) => sortBy(exchanges, 'date');
export const mapAndSortExchanges = mapAndSortEntities(sortExchanges);
export const getExchanges = createSelector(getExchangeIds, getExchangeEntities, mapAndSortExchanges);
export const getExchangesWithSchedule = createSelector(
  getExchanges,
  getEnhancedScheduleEntities,
  getPermissionState,
  (exchanges, schedules, permissionState) =>
    exchanges
      .map((exchange) => {
        const occurrence_id = `${exchange.roster_id}:${exchange.date}`;

        return {
          ...exchange,
          Schedule: schedules[occurrence_id],
        };
      })
      .filter((exchange) => {
        if (!exchange.Schedule) {
          return false;
        }

        const departmentId = exchange.Schedule.Team && exchange.Schedule.Team.department_id;
        if (!departmentId) {
          return false;
        }

        //canView Schedule
        const canViewScheduleCheck: PermissionCheck = {
          permissions: ['View all rosters', 'View own roster'],
          userId: 'me',
          departments: departmentId,
        };

        if (!hasPermission(canViewScheduleCheck, permissionState)) {
          return false;
        }

        // this is my request:
        if (exchange.user_id === permissionState.userId) {
          return true;
        }

        // I've accepted the request
        if (exchange.accepted_by === permissionState.userId && exchange.status === 'Pending supervisor') {
          return true;
        }

        // I'm invited and status is pending employee
        if (
          exchange.status === 'Pending employee' &&
          exchange.ExchangesUser &&
          exchange.ExchangesUser.some((user) => user.user_id === permissionState.userId)
        ) {
          return true;
        }

        const canViewExchange: PermissionCheck = {
          permissions: ['Approve exchange', 'Request exchange'],
          userId: 'me',
          departments: departmentId,
        };

        return hasPermission(canViewExchange, permissionState);
      }),
);

export const getExchange = (id: string) => createSelector(getExchangeEntities, (entities) => mapEntity(id, entities));

export const getExchangeWithSchedule = (id: string) =>
  createSelector(getExchangesWithSchedule, (entities: ExchangeWithScheduleModel[]) =>
    find(entities, (entity) => entity.id === id),
  );

export const getIncomingExchangesForAuthenticatedUser = createSelector(
  getAuthenticatedUserId,
  getExchangesWithSchedule,
  (userId: string, exchanges: ExchangeModel[]): ExchangeModel[] =>
    exchanges.filter((exchange) => {
      if (
        exchange.ExchangesUser === undefined ||
        !exchange.ExchangesUser.some((user) => user.user_id === userId && !user.deleted)
      ) {
        return false;
      }
      return exchange.status === 'Pending employee' || exchange.status === 'Pending supervisor';
    }),
);

export const getOutcomingExchangesForAuthenticatedUser = createSelector(
  getAuthenticatedUserId,
  getExchangesWithSchedule,
  (userId: string, exchanges: ExchangeModel[]): ExchangeModel[] =>
    exchanges.filter((exchange) => {
      if (exchange.User === undefined || !(exchange.User.id === userId)) {
        return false;
      }
      return exchange.status === 'Pending employee' || exchange.status === 'Pending supervisor';
    }),
);

export const getExchangesForAuthenticatedUserWithinPeriod = (minDate, maxDate) =>
  createSelector(
    getIncomingExchangesForAuthenticatedUser,
    getOutcomingExchangesForAuthenticatedUser,
    (incomingExchanges: ExchangeModel[], outgoingExchanges: ExchangeModel[]) => {
      const exchanges: ExchangeModel[] = [...incomingExchanges, ...outgoingExchanges];
      const filteredExchanges = exchanges.filter(periodFilter(minDate, maxDate));
      return sortBy(filteredExchanges, 'date');
    },
  );

export const getExchangesForSupervisorApproval = createSelector(
  getExchangesWithSchedule,
  getPermissionState,
  (exchanges: ExchangeWithScheduleModel[], permissionState): ExchangeWithScheduleModel[] =>
    exchanges.filter((exchange) => {
      const departmentId = exchange.Schedule.Team && exchange.Schedule.Team.department_id;
      const canApproveExchange: PermissionCheck = {
        permissions: ['Approve exchange'],
        userId: 'me',
        departments: departmentId,
      };
      if (!hasPermission(canApproveExchange, permissionState)) {
        return false;
      }
      return exchange.status === 'Pending supervisor';
    }),
);

export const getExchangesForEmployeeApproval = createSelector(
  getExchangesWithSchedule,
  (exchanges: ExchangeModel[]): ExchangeModel[] =>
    exchanges.filter((exchange) => exchange.status === 'Pending employee'),
);
