import { format } from '../../../shared/date.helper';
import { createSelector } from 'reselect';
import { getEmployeesForPeriod } from '../../auth/permission.helper';
import { getVisibleTeamsGroupedByDepartment } from '../team/team.service';
import { EmployeeModel } from '../employee/employee.model';

export const getEmployeeIdsPerTeamForDepartment = (
  permissions,
  minDate: string = format(new Date(), 'yyyy-MM-dd'),
  maxDate: string = format(new Date(), 'yyyy-MM-dd'),
  departmentId: string
) =>
  createSelector(
    getEmployeesForPeriod(permissions, minDate, maxDate, departmentId),
    getVisibleTeamsGroupedByDepartment(true),
    (employees: EmployeeModel[], teamsPerDepartment) => {
      const teams = teamsPerDepartment[departmentId] || [];
      return employees
        .filter((employee: EmployeeModel) => {
          if (!employee.Team) {
            return false;
          }
          return teams.some((team) => employee.Team.includes(team.id));
        })
        .map((employee) => employee.id);
    }
  );
