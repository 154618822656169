import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Exchange]';

export const exchangeActionType = {
  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  LOAD_ALL_PENDING: `${ACTION_PREFIX} Load All Pending`,
  LOAD_ALL_PENDING_SUCCESS: `${ACTION_PREFIX} Load All Pending Success`,
  LOAD_ALL_PENDING_FAILED: `${ACTION_PREFIX} Load All Pending Failed`,

  LOAD_INCOMING: `${ACTION_PREFIX} Load Incoming`,
  LOAD_INCOMING_SUCCESS: `${ACTION_PREFIX} Load Incoming Success`,
  LOAD_INCOMING_FAILED: `${ACTION_PREFIX} Load Incoming Failed`,

  LOAD_OUTGOING: `${ACTION_PREFIX} Load Outgoing`,
  LOAD_OUTGOING_SUCCESS: `${ACTION_PREFIX} Load Outgoing Success`,
  LOAD_OUTGOING_FAILED: `${ACTION_PREFIX} Load Outgoing Failed`,

  ACCEPT_INCOMING: `${ACTION_PREFIX} Accept Incoming`,
  ACCEPT_INCOMING_SUCCESS: `${ACTION_PREFIX} Accept Incoming Success`,
  ACCEPT_INCOMING_FAILED: `${ACTION_PREFIX} Accept Incoming Failed`,

  REJECT_INCOMING: `${ACTION_PREFIX} Reject Incoming`,
  REJECT_INCOMING_SUCCESS: `${ACTION_PREFIX} Reject Incoming Success`,
  REJECT_INCOMING_FAILED: `${ACTION_PREFIX} Reject Incoming Failed`,

  RETRACT_OUTGOING: `${ACTION_PREFIX} Retract Outgoing`,
  RETRACT_OUTGOING_SUCCESS: `${ACTION_PREFIX} Retract Outgoing Success`,
  RETRACT_OUTGOING_FAILED: `${ACTION_PREFIX} Retract Outgoing Failed`,

  ACCEPTED_BY_SUPERVISOR: `${ACTION_PREFIX} Accepted By Supervisor`,
  ACCEPTED_BY_SUPERVISOR_SUCCESS: `${ACTION_PREFIX} Accepted By Supervisor Success`,
  ACCEPTED_BY_SUPERVISOR_FAILED: `${ACTION_PREFIX} Accepted By Supervisor Failed`,

  REJECTED_BY_SUPERVISOR: `${ACTION_PREFIX} Rejected By Supervisor`,
  REJECTED_BY_SUPERVISOR_SUCCESS: `${ACTION_PREFIX} Rejected By Supervisor Success`,
  REJECTED_BY_SUPERVISOR_FAILED: `${ACTION_PREFIX} Rejected By Supervisor Failed`,

  REMOVE_REQUESTED_BY_SUPERVISOR: `${ACTION_PREFIX} Remove Requested By Supervisor`,
  REMOVE_REQUESTED_BY_SUPERVISOR_SUCCESS: `${ACTION_PREFIX} Remove Requested By Supervisor Success`,
  REMOVE_REQUESTED_BY_SUPERVISOR_FAILED: `${ACTION_PREFIX} Remove Requested By Supervisor Failed`,

  DELETE_EXCHANGE: `${ACTION_PREFIX} Delete Exchange`,
  DELETE_EXCHANGE_SUCCESS: `${ACTION_PREFIX} Delete Exchange Success`,
  DELETE_EXCHANGE_FAILED: `${ACTION_PREFIX} Delete Exchange Failed`,
};

export class ExchangeAction {
  static fetch(id: any) {
    return {
      type: exchangeActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: exchangeActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: exchangeActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  static add(id: any) {
    return {
      type: exchangeActionType.ADD,
      payload: id,
    };
  }

  static addSuccess(response) {
    return {
      type: exchangeActionType.ADD_SUCCESS,
      payload: response,
    };
  }

  static addFailed(err) {
    return {
      type: exchangeActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(locationId, data): Action {
    return {
      type: exchangeActionType.UPDATE,
      payload: data,
    };
  }

  static updateSuccess(response): Action {
    return {
      type: exchangeActionType.UPDATE_SUCCESS,
      payload: response,
    };
  }

  static updateFailed(err): Action {
    return {
      type: exchangeActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static loadAllPending(): Action {
    return {
      type: exchangeActionType.LOAD_ALL_PENDING,
    };
  }

  static loadAllPendingSuccess(data): Action {
    return {
      type: exchangeActionType.LOAD_ALL_PENDING_SUCCESS,
      payload: data,
    };
  }

  static loadAllPendingFailed(err): Action {
    return {
      type: exchangeActionType.LOAD_ALL_PENDING_FAILED,
      payload: err,
    };
  }

  static loadIncoming(): Action {
    return {
      type: exchangeActionType.LOAD_INCOMING,
    };
  }

  static loadIncomingSuccess(data): Action {
    return {
      type: exchangeActionType.LOAD_INCOMING_SUCCESS,
      payload: data,
    };
  }

  static loadIncomingFailed(err): Action {
    return {
      type: exchangeActionType.LOAD_INCOMING_FAILED,
      payload: err,
    };
  }

  static loadOutgoing(): Action {
    return {
      type: exchangeActionType.LOAD_OUTGOING,
    };
  }

  static loadOutgoingSuccess(data): Action {
    return {
      type: exchangeActionType.LOAD_OUTGOING_SUCCESS,
      payload: data,
    };
  }

  static loadOutgoingFailed(err): Action {
    return {
      type: exchangeActionType.LOAD_OUTGOING_FAILED,
      payload: err,
    };
  }

  static acceptIncoming(): Action {
    return {
      type: exchangeActionType.ACCEPT_INCOMING,
    };
  }

  static acceptIncomingSuccess(data): Action {
    return {
      type: exchangeActionType.ACCEPT_INCOMING_SUCCESS,
      payload: data,
    };
  }

  static acceptIncomingFailed(err): Action {
    return {
      type: exchangeActionType.ACCEPT_INCOMING_FAILED,
      payload: err,
    };
  }

  static rejectIncoming(): Action {
    return {
      type: exchangeActionType.REJECT_INCOMING,
    };
  }

  static rejectIncomingSuccess(data): Action {
    return {
      type: exchangeActionType.REJECT_INCOMING_SUCCESS,
      payload: data,
    };
  }

  static rejectIncomingFailed(err): Action {
    return {
      type: exchangeActionType.REJECT_INCOMING_FAILED,
      payload: err,
    };
  }

  static acceptedBySupervisor(): Action {
    return {
      type: exchangeActionType.ACCEPTED_BY_SUPERVISOR,
    };
  }

  static acceptedBySupervisorSuccess(data): Action {
    return {
      type: exchangeActionType.ACCEPTED_BY_SUPERVISOR_SUCCESS,
      payload: data,
    };
  }

  static acceptedBySupervisorFailed(err): Action {
    return {
      type: exchangeActionType.ACCEPTED_BY_SUPERVISOR_FAILED,
      payload: err,
    };
  }

  static rejectedBySupervisor(): Action {
    return {
      type: exchangeActionType.REJECTED_BY_SUPERVISOR,
    };
  }

  static rejectedBySupervisorSuccess(data): Action {
    return {
      type: exchangeActionType.REJECTED_BY_SUPERVISOR_SUCCESS,
      payload: data,
    };
  }

  static rejectedBySupervisorFailed(err): Action {
    return {
      type: exchangeActionType.REJECTED_BY_SUPERVISOR_FAILED,
      payload: err,
    };
  }

  static removeRequestedBySupervisor(): Action {
    return {
      type: exchangeActionType.REMOVE_REQUESTED_BY_SUPERVISOR,
    };
  }

  static removeRequestedBySupervisorSuccess(data): Action {
    return {
      type: exchangeActionType.REMOVE_REQUESTED_BY_SUPERVISOR_SUCCESS,
      payload: data,
    };
  }

  static removeRequestedBySupervisorFailed(err): Action {
    return {
      type: exchangeActionType.REMOVE_REQUESTED_BY_SUPERVISOR_FAILED,
      payload: err,
    };
  }

  static deleteExchangeRequest(): Action {
    return {
      type: exchangeActionType.DELETE_EXCHANGE,
    };
  }

  static deleteExchangeRequestSuccess(data): Action {
    return {
      type: exchangeActionType.DELETE_EXCHANGE_SUCCESS,
      payload: data,
    };
  }

  static deleteExchangeRequestFailed(err): Action {
    return {
      type: exchangeActionType.DELETE_EXCHANGE_FAILED,
      payload: err,
    };
  }
}
